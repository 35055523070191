<template>
  <div class="w-100 mb-4">
    <Header />
  </div>
</template>

<script>
import Header from "../components/Header";

import { mapState, mapActions } from "vuex";

export default {
  components: { Header },
  computed: {
    ...mapState("indicators", ["indicator"])
  },
  methods: {
    ...mapActions("indicators", ["ActionShow"])
  },
  async created() {
    await this.ActionShow({ id: this.$route.params.id });
  }
};
</script>

<style></style>
